import React, { useEffect } from "react";

const BookeoWidget = () => {
  useEffect(() => {
    // Append the Bookeo script to a specific div, not to the body
    const script = document.createElement("script");
    script.src = "https://bookeo.com/widget.js?a=425574XYJJ918F9FE963B0"; // Use your actual widget URL with API key if needed
    script.async = true;

    // Target the specific div where the widget should load
    const widgetContainer = document.getElementById("bookeo-widget-container");
    if (widgetContainer) {
      widgetContainer.appendChild(script);
    }

    // Cleanup: Remove the script if the component is unmounted
    return () => {
      if (widgetContainer) {
        widgetContainer.innerHTML = ""; // Clear the container on unmount
      }
    };
  }, []);

  return (
    <div className="bookeo-widget">
      {/* Div where the widget will be injected */}
      <div id="bookeo-widget-container"></div>
    </div>
  );
};

export default BookeoWidget;
