import React from "react";
import BookeoWidget from "../../components/BookeoWidget"; // Ensure correct path

export default function SchedulePage() {
  return (
    <div className="container p-0 my-0 mt-3 pageClass px-3">
      {/* Integrate the Bookeo widget */}
      <div className="pt-2">
        <BookeoWidget />
      </div>
    </div>
  );
}
